import {Box, Button, Container, Stack, SvgIcon, Typography} from "@mui/material";
import * as React from "react";
import {useSelector} from "react-redux";

export default function Sorry() {
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    return (
        <Container disableGutters sx={{width: '420px'}}>
            <Stack
                spacing={1}
                sx={{
                    display: 'flex',
                    width: '100%',
                    minHeight: domainInstallAlert ? 'calc(100vh - 64px - 48px)' : 'calc(100vh - 64px)',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <SvgIcon sx={{width: 96, height: 96, marginBottom: 2}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
                        <rect width="96" height="96" rx="48" fill="#EAEBEB"/>
                        <path
                            d="M47.968 16C30.304 16 16 30.336 16 48C16 65.664 30.304 80 47.968 80C65.664 80 80 65.664 80 48C80 30.336 65.664 16 47.968 16Z"
                            fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M16 48C16 30.336 30.336 16 48 16C65.664 16 80 30.336 80 48C80 65.664 65.664 80 48 80C30.336 80 16 65.664 16 48ZM48 43.488L59.488 32L64 36.512L52.512 48L64 59.488L59.488 64L48 52.512L36.512 64L32 59.488L43.488 48L32 36.512L36.512 32L48 43.488Z"
                              fill="#81939C"/>
                    </svg>
                </SvgIcon>
                <Typography
                    sx={{
                        textAlign: 'center',
                        color: 'rgba(60, 64, 67, 1)',
                        fontFamily: `"Open Sans"`,
                        fontSize: 28,
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '40px',
                        letterSpacing: '-0.56px',
                    }}>Sorry!<br/>You’re not eligible for a new trial</Typography>
                <Typography
                    sx={{
                        padding: '0 25px 0 25px',
                        textAlign: 'center',
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontFamily: `"Open Sans"`,
                        fontSize: 17,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '-0.34px',
                    }}>Please contact us so we can help get you started with Collaborative Contacts.</Typography>
                <Box>
                    <Button
                        variant='contained'
                        size="medium"
                        sx={{
                            marginTop: 2,
                        }}
                    >
                        <Typography variant={'button'} sx={{textTransform: "none"}}>Contact customer
                            support</Typography>
                    </Button>
                </Box>
            </Stack>
        </Container>

    )
}
